import { type BaseSyntheticEvent, useMemo, useState } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Select } from '@web-solutions/core/ui-elements';

import { CheckboxWithLabel } from 'src/screens/components/checkbox-with-label';

import type { BirthTimeType } from 'src/screens/personalization/types';

import classes from './style.module.scss';

const tKey = "personalization.form.time_birth";

interface Props {
  onTimeChange: (e: any) => void;
  birthTime: BirthTimeType;
}

export const BirthTime: React.FC<Props> = ({ onTimeChange, birthTime }) => {
  const [dontNowChecked, setDontNowChecked] = useState<boolean>(false);

  const hoursOptions = useMemo(() => Array.from({ length: 24 }, (_, i) => ({ value: String(i).padStart(2, '0') })), []);

  const minutesOptions = useMemo(() => Array.from({ length: 60 }, (_, i) => ({ value: String(i).padStart(2, '0') })), []);

  const handleChange = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    const data: BirthTimeType = {
      hour: '12',
      minutes: '00',
      dont_now: dontNowChecked
    };

    const formData = new FormData(e.currentTarget as HTMLFormElement);

    formData.forEach((value, key) => {
      if (Object.hasOwn(data, key)) {
        data[key as keyof BirthTimeType] = value as string & boolean;
      }
    });

    onTimeChange(data);
  };

  const handleCheckChange = (e: BaseSyntheticEvent) => {
    const { checked } = e.target;

    setDontNowChecked(checked);

    onTimeChange({ dont_now: checked, minutes: '', hour: '' })
  }

  return (
    <div className={classes.birth_time}>
      <form className={classes.birth_time_selects} onChange={handleChange}>
        <Select
          disabled={birthTime.dont_now}
          value={birthTime.hour}
          name="hour"
          options={hoursOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthTime.hour })}
          placeholderText={t(`${tKey}.hour_placeholder`)}
        />
        <p className={classes.colon}>:</p>
        <Select
          disabled={birthTime.dont_now}
          value={birthTime.minutes}
          name="minutes"
          options={minutesOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthTime.minutes })}
          placeholderText={t(`${tKey}.minutes_placeholder`)}
        />
      </form>
      <CheckboxWithLabel
        name='dont_now'
        text={t(`${tKey}.checkbox_text`)}
        isChecked={dontNowChecked}
        onChange={handleCheckChange}
      />
    </div>
  )
}