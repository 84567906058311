import _mapValues from 'lodash/mapValues';

export const QUIZ_NAMES = {
  QUIZ_WELCOME: 'QUIZ_WELCOME',
  QUIZ_MAILING: 'QUIZ_MAILING',
  QUIZ_TRANSITION_HAND: 'QUIZ_TRANSITION_HAND',
  QUIZ_TRANSITION_MAP: 'QUIZ_TRANSITION_MAP',
} as const;

export const QUIZ: { [key in QuizNameKeys]: string } = _mapValues(QUIZ_NAMES, (v) => '/' + v);

export const PUBLIC_ROUTES = {
  MAIN: '/main',
  MAGIC: '/magic',
}

export const PROTECTED_ROUTES = {
  SUCCESS: '/success',
  SHIPPING: '/shipping',
  CART: '/cart',
}

export const ROUTES = {
  ...PROTECTED_ROUTES,
  ...PUBLIC_ROUTES,
  ...QUIZ,
};


type QuizNameGeneralKeys = keyof typeof QUIZ_NAMES;
export type QuizNamesGeneralValues = typeof QUIZ_NAMES[QuizNameGeneralKeys];

export type QuizNameKeys = QuizNameGeneralKeys;
export type QuizNamesValues = Array<QuizNamesGeneralValues>;