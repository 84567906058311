import { createAsyncThunk } from '@reduxjs/toolkit';

import { init as initRouting } from '@web-solutions/base-app/store/routing/actions';

import { init as initRemoteConfig } from 'core/store/remote-config/actions';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { auth, init } from '../profile/actions';


import { getMagnusToken } from './utils';

import { appSlice } from ".";

export const { setLoaded, setPending } = appSlice.actions;

export const load = createAsyncThunk(
  `${appSlice.name}/load`,
  async (_, { dispatch }) => {
    try {
      await dispatch(auth());
      await dispatch(init());
      await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG));
      await dispatch(initRouting());
    } catch (error) {
      console.warn('[ERROR INIT APP]', error);
    } finally {
      dispatch(setLoaded(true));
    }
  }
);