import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from '@web-solutions/core/utils/normalize-blocks';

import { Flow } from 'src/constants/remote-config';
import { QUIZ_NAMES } from 'src/constants/routes';

const ROUTES = Object.values(QUIZ_NAMES);

const getFlow = (state: any) => state.remoteConfig.flow;

export const selectFlowBlocks = createSelector(
  [getFlow],
  (flow): Flow[] => {
    let f = normalizeBlocks(flow) as Flow[];

    f = f.map(i => _intersection(i, ROUTES)).filter(i => !!i.length);

    return f;
  },
);

export const selectFlow = createSelector(
  [selectFlowBlocks],
  (flow): Flow => flow.flat()
);