import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type RemoteConfig as CoreRemoteConfig,
  BaseOfferProductTypes,
  LString
} from 'core/constants/remote-config';

import { SummarySection } from 'src/types/remote-config';

import { QuizNamesValues } from 'src/constants/routes';

export type OfferProductId = 'birth_chart_hardcover'

export type Flow = QuizNamesValues;

export interface RemoteConfig extends CoreRemoteConfig<Flow[number]> {
  landingStack: Array<SummarySection>,
  presummaryStack: Array<SummarySection>,
  availableShippingCountries: string[],
  genderPageTitle: LString,
  welcomeGenderType: 'image' | 'video',
  product: Omit<BaseOfferProductTypes<OfferProductId>, 'title' | 'subTitle'>
  shopMode: 'auto_add_to_cart' | 'manual_add_to_cart',
  birthPlaceData: {
    suggestCurrentPlace: boolean,
  }
  magicDurationLoad?: number,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,
  landingStack: [],
  presummaryStack: [],
  genderPageTitle: '',
  welcomeGenderType: 'image',
  availableShippingCountries: [],
  flow: [],
  product: {
    notActivePrice: '',
    activePrice: '',
    id: 'birth_chart_hardcover',
    discount: '',
    price: 0,
    currency: "USD"
  },
  shopMode: 'auto_add_to_cart',
  birthPlaceData: {
    suggestCurrentPlace: false,
  },
  magicDurationLoad: 12000
};
