export const ASCENDANT = 'ascendant';
export const MARS = 'mars';
export const VENUS = 'venus';
export const MERCURY = 'mercury';
export const MOON = 'moon';
export const SUN = 'sun';
export const PLUTO = 'pluto';
export const JUPITER = 'jupiter';
export const SATURN = 'saturn';
export const URANUS = 'uranus';
export const NEPTUNE = 'neptune';