import _throttle from 'lodash/throttle';
import { useEffect, useMemo, useRef } from 'react';

import Analytics from '@web-solutions/module-analytics';

import { checkIsBodyFixed } from '@web-solutions/core/utils/check-is-body-fixed';

type Params = {
  isSummaryReady: boolean,
  usersPercentToActivateScrollMetric?: string,
}

export const useSummaryViewedRate = ({ usersPercentToActivateScrollMetric, isSummaryReady }: Params) => {
  const maxViewedPercent = useRef(0);
  const coreElement = useRef<HTMLElement>(document.documentElement);
  const scrollElement = useRef<HTMLElement | Window>(window);

  const randomNumber = useMemo(() => Math.floor(Math.random() * 100) + 1, []);

  useEffect(() => {
    if (checkIsBodyFixed()) {
      const rootElement = document.getElementById('root');

      if (rootElement) {
        coreElement.current = rootElement;
        scrollElement.current = rootElement;
      }
    }
  }, [])

  useEffect(() => {
    if (isSummaryReady && usersPercentToActivateScrollMetric && randomNumber <= parseInt(usersPercentToActivateScrollMetric)) {
      const windowHeight = window.innerHeight;
      const documentHeight = coreElement.current.scrollHeight;

      const visiblePercent = Math.round((windowHeight / documentHeight) * 100);
      const roundedVisiblePercent = Math.round(visiblePercent / 5) * 5;

      maxViewedPercent.current = roundedVisiblePercent;

      Analytics.setUserProperty('summaryViewedRate', `${roundedVisiblePercent}%`);
    }
  }, [isSummaryReady, usersPercentToActivateScrollMetric, randomNumber]);

  useEffect(() => {
    if (usersPercentToActivateScrollMetric && randomNumber <= parseInt(usersPercentToActivateScrollMetric)) {
      const handleScroll = _throttle(() => {
        const windowHeight = window.innerHeight;
        const documentHeight = coreElement.current.scrollHeight;
        const scrolled = coreElement.current.scrollTop;

        const viewedArea = windowHeight + scrolled;
        const viewedPercent = (viewedArea / documentHeight) * 100;
        const roundedMaxViewedPercent = Math.round(viewedPercent / 5) * 5;

        if (roundedMaxViewedPercent > maxViewedPercent.current) {
          maxViewedPercent.current = roundedMaxViewedPercent;

          Analytics.setUserProperty('summaryViewedRate', `${roundedMaxViewedPercent}%`);
        }
      }, 500);

      scrollElement.current.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [usersPercentToActivateScrollMetric, randomNumber]);
}

