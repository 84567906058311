import React from "react";
import lazy, { PreloadableComponent } from 'react-lazy-with-preload';

import BookLanding from 'src/screens/book-landing';
import { OrderSuccess } from 'src/screens/order/order-success';
import { Shipping } from "src/screens/shipping";
import Cart from "src/screens/cart";
import Magic from "src/screens/magic";

import { QUIZ_NAMES, QuizNameKeys, ROUTES } from "./routes";

export const SCREENS = {
  [ROUTES.MAIN]: <BookLanding />,
  [ROUTES.SHIPPING]: <Shipping />,
  [ROUTES.SUCCESS]: <OrderSuccess />,
  [ROUTES.CART]: <Cart />,
  [ROUTES.MAGIC]: <Magic />
};

export const QUIZ_SCREENS: { [key in QuizNameKeys]: PreloadableComponent<React.FC<any>> } = {
  [QUIZ_NAMES.QUIZ_WELCOME]: lazy(() => import('src/screens/quiz/gender/common')),
  [QUIZ_NAMES.QUIZ_MAILING]: lazy(() => import('src/screens/quiz/mailing')),
  [QUIZ_NAMES.QUIZ_TRANSITION_HAND]: lazy(() => import('src/screens/quiz/transitions/hand')),
  [QUIZ_NAMES.QUIZ_TRANSITION_MAP]: lazy(() => import('src/screens/quiz/transitions/map')),
}