import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import remoteConfig from '@web-solutions/core/store/remote-config';
import billing from '@web-solutions/core/store/billing';

import { routingSlice, whitelist as whitelistRouting } from 'src/store/routing';
import { appSlice, blacklist as appBlacklist } from 'src/store/app';
import { cartSlice, blacklist as cartBlacklist } from 'src/store/cart';
import { profileSlice, blacklist as profileBlacklist } from 'src/store/profile';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const rootReducer = combineReducers({
    app: persistReducer({
      key: `app`,
      storage,
      blacklist: appBlacklist,
    }, appSlice.reducer),
    remoteConfig: persistReducer({
      key: `remoteConfig`,
      storage,
      blacklist: remoteConfig.blacklist,
      whitelist: remoteConfig.whitelist,
    }, remoteConfig),
    routing: persistReducer({
      key: `routing`,
      storage,
      whitelist: whitelistRouting,
    }, routingSlice.reducer),
    profile: persistReducer({
      key: `profile`,
      storage,
      blacklist: profileBlacklist,
    }, profileSlice.reducer),
    cart: persistReducer({
      key: `cart`,
      storage,
      blacklist: cartBlacklist,
    }, cartSlice.reducer),
    billing: persistReducer({
      key: 'billing',
      storage,
      blacklist: billing.blacklist,
      whitelist: billing.whitelist,
    }, billing),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
